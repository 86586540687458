.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9;
}

.Instructionbox {
    position: relative;
    width: 60%;
    margin: 0 auto;
    height: 87vh;
    margin-top: 10vh;
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
}