@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap');

.s1-v * {
    font-family: 'Open Sans';
    font-style: normal;
    margin: 0px;
}

.s1-v {
    background: #F5F3F7;
    height: 100vh;
}
.s1-v::-webkit-scrollbar {
    display: none;
  }

.red-alert {
    background: rgba(255, 0, 0, 0.114);
    color: #fafafa;
    line-height: 30px;
    font-size: 16px;
    text-align: center;
    font-size: 600;
    width: 300px;
    margin: 0px auto;
  position: absolute;
  margin-left: 25%;
}

.s1-v .header {
    border-bottom: 1px solid #d9d1e081;
    display: flex;
    height: 60px;
    background: #fff;
    align-items: center;
}

.s1-v .header div a button {
    padding: 0px 16px;
    height: 40px;
    background: linear-gradient(0deg, #2CABF5, #2CABF5), #FFFFFF;
    border-radius: 24px;
    font-weight: 600;
    font-size: 20px;
    border: none;
    line-height: 40px;
    cursor: pointer;
    color: #FFFFFF;
}

.s1-v .header div {
    padding: 0px 32px;
    display: flex;
    margin-right: 16px;
    align-items: center;
    justify-content: center;
    height: 100%;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.2);
}

.s1-v .header p {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    letter-spacing: 0.01em;
    color: #323C47;
}

.s1-v .header>a {
    margin-left: auto;
    margin-right: 24px;
}

.s1-v .header>a button {
    background: #FFB3B3;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    height: 40px;
    cursor: pointer;
    letter-spacing: 0.01em;
    border: none;
    padding: 0px 16px;
    color: #A20000;
}

.s1-v .header a button:focus,
.s1-v>button:focus,
.s1-v>button {
    outline: none;
    border: none;
    cursor: pointer;
}

.s1-v .height {
    border-radius: 8px;
    /*    height: calc(100vh - 150px); */
    display: flex;
    margin-top: 50px;
    padding: 40px 32px 0px;
}

.s1-v .height>div {
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.08), -1px -2px 16px rgba(0, 0, 0, 0.08);
    min-height: calc(100vh - 250px);
    padding: 16px;
    padding-left: 0px;
    border-radius: 8px;
    background: #fff;
}

.s1-v .height>div.name_details {
    width: 350px;
    margin-left: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.s1-v .height>div.name_details .br {
    height: 44px;
}

.name_details>img {
    width: 146px;
    height: 143px;
}

.name_details>p {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */


    margin-bottom: 12px;
    color: #979797;

}

.name_details>span {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;

    letter-spacing: 0.01em;

    color: #323C47;
}

.s1-v .height>div:not(.name_details) {
    width: calc(100% - (350px + 24px));

}

.name_details h1 {
    color: red;
    font-size: 24px;
    font-weight: 300;
}

.name_details h6 {
    color: #888;
    font-size: 16px;
    font-weight: 300;
    margin-top: 12px;
}

.name_details>button {
    background: #0097F9;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    width: 257px;
    height: 40px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    margin: 25px auto;
    display: block;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
}

.name_details>button:focus {
    outline: none;
}

.name_details h1 img {
    width: 24px;
    height: auto;
    margin-right: 8px;
}

.button_format {
    background: #0097F9;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    width: 357px;
    height: 40px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    margin: 25px auto;
    display: block;
    color: #FFFFFF;
    border: none;

}

.liveclassbutton {
    background: #FFFFFF;
    border: 1px solid #0097F9;
    border-radius: 4px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    width: 357px;
    height: 40px;
    color: #0097F9;

}

.liveclasstext {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.01em;

    color: #979797;

}

.tag {

    height: 27px;
    width: 158px;
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    /* identical to box height */
    text-align: center;
    margin-top: 8px;
    color: #FFFFFF;
    display: block;
    padding-right: 22px;
}

.tag.mock {
    background: #E3A120;
    margin-left: -17px;
}

.tag.online {
    background: #0BE1BB;
    margin-left: -17px;
}

.tag.prectice {
    background: #FA6E86;
    margin-left: -17px;
}

.tag::after {
    width: 0;
    height: 0;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    content: ' ';
    right: -140px;
    top: -27px;
    position: relative;
    display: block;
    border-right: 18px solid #fff;
}

.s1-v .height>div:not(.name_details)>h1 {
    margin: 30px 32px 0px;
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    letter-spacing: 0.01em;

    color: #323C47;

}

.s1-v .height>div:not(.name_details)>.line {
    border-top: 1px solid #D9D9D9;
    margin: 16px 32px 0px;
}

.flex-300 {
    padding: 0px 50px 0px;
    display: flex;
    flex-wrap: wrap;
    /*  align-items: flex-start;
    height: calc(100vh - 362px); */
}

.flex-300>div {
    width: calc(100% / 3);
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 4vh 0px 0px;
}

.flex-300 img {
    height: 32px;
    margin-right: 16px;
    width: 32px;
    vertical-align: top;
}

.flex-300>div>div {
    display: inline-block;
}

.flex-300 label {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    padding-top: 4px;
    letter-spacing: 0.01em;
    color: #979797;
}

.flex-300 h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #323C47;

}

.flex-300 .flex {
    width: 100%;
    display: flex;
}

.flex-300 .flex.dt img {
    height: 16px;
    width: 16px;
    vertical-align: middle;
    margin: 0px 24px 0px 8px;

}

.flex-300 .flex.dt {
    margin-top: 12px;
}

.flex-300 .flex.dt p {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #323C47;

}

.flex-300 div:not(.dt) h6 {
    margin-top: 8px;
}

@media only screen and (max-device-width: 850px) {
    .s1-v {
        height: auto;
    }

    .s1-v .header {
        height: auto;
        flex-wrap: wrap;
        padding: 8px;
        border-bottom: 4px solid #d9d1e081;
    }



    .s1-v .header div {
        display: inline;
        width: auto;
        box-shadow: none;


    }

    .s1-v .header>a {
        margin-right: 0px;
    }

    .s1-v .header p {
        width: calc(100% - 155px);
        margin-bottom: 4px;
    }

    .s1-v .height {
        padding: 8px;
        height: auto;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .s1-v .height>div:not(.name_details),
    .s1-v .height>div.name_details {
        width: 100%;
        padding: 8px;
        margin-bottom: 8px;
        height: auto;
    }

    .flex-300 {
        padding: 8px;
        display: block;
        height: auto;
    }

    .flex-300>div {
        width: 100%;
        margin-top: 24px;
    }

    .s1-v>button {
        width: 80%;
    }

    .s1-v .height>div.name_details {
        margin-left: 0px;
    }
}