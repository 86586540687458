body {
  margin: 0;
  font-family: 'Roboto', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: 'Roboto', Helvetica, sans-serif,
    monospace;
}
.fullscreen{
background-color: white;
}
