.remainingTime {
    color: red;
    font-size: 22px;
    font-weight: 700;
}

.studentName {
    font-size: 16px;
    font-weight: bold;
    font-family: sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
}

.contactNum {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: 0.01em;
    color: #979797;

}