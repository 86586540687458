.container {
    height: 100vh;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.headerdiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.reportcontainer {
    width: 100%;
    display: flex;
    justify-content: center;
   
}

.reportmaindiv {
    width: 80%;
    padding: 15px;
    display: flex;
    flex-direction: row;
}


.testdetail {

    width: 50%;

}

.markdetails {
    width: 50%;
}

.buttonStyle {
    cursor: pointer;
    width: calc(100%/3 - 10px);
    /* width: 20%; */
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: rgba(51, 77, 110, 0.9);
    text-align: center;
}
.buttonStylesubject {
    cursor: pointer;
    /* width: 40%; */
    width: calc(100%/3 - 10px);
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: rgba(51, 77, 110, 0.9);
    text-align: center;
}
.onClickButtonsubjeact{
    cursor: pointer;
    /* width: 40%; */
    width: calc(100%/3 - 10px);
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #1B62E6;
    border-bottom-color:#1B62E6;
    border-bottom: 2px solid;
    align-items: center;
    text-align: center;
    padding-bottom: 5px;

}
.onClickButton{
    cursor: pointer;
    width: calc(100%/3 - 10px);
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #1B62E6;
    border-bottom-color:#1B62E6;
    border-bottom: 2px solid;
    align-items: center;
    text-align: center;
    padding-bottom: 5px;

}