.maindiv::-webkit-scrollbar{
    display: none;
}
.maindiv{
    width: 80%;
    height: 50vh;
    overflow: scroll;
    overflow-x: hidden;
   
}

.maindivfirst::-webkit-scrollbar{
    display: none;
}
.maindivfirst {
    width: 80%;
    height: 50vh;
    overflow: scroll;
    justify-content: center;
    overflow-x: hidden;
    display: flex;
    justify-content: center;

}

.maincard {
    width: calc(100%/3 - 50px);
    display: inline-block;
    height: fit-content;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    ;
    background: #FFFFFF;
    border-radius: 0px 5px 5px 5px;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 16px; 
}
.subjectlable{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #FB9860;

}

.lableName {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.valueText {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #454545;
}