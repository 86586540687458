.confirmationText{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    margin-left: 5px;
}
.instructionTitle{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 12px;
    letter-spacing: 0.01em;
    margin-bottom: 20px;
   
}

.header{
    border-bottom: 1px solid #ddd;
    box-shadow: 0pt 0pt 2pt #eee;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 6vh;
    .logo img{
        max-height: 48px;
    }
    .test-name{
        font-size: 1.1rem;
        font-weight: bold;
        margin: auto;
    }
}

.body-wrapper{
    padding-top: 6vh;
    height: 100vh;
    .left-pallete{
        background: #fff;
        height: 100%;
        .title-bar{
            padding: 0.2em 0em;
            background: #f5f5f5;
            border-bottom: 1px solid #ddd;
            font-size: 1.1rem;
            font-weight: 700;
        }
        .instructions-wrapper{
            max-height: 94%;
            overflow-y: auto;
            padding: 1em 0;
            padding-bottom: 2em;
            
            li{
                color: #555;
            }
        }
    }
    .right-pallete{
        background: #fff;
        border-left: 1px solid #ddd;
    }
}

.legends-listing {
    li{
        margin-bottom: 10px;
        img{  
            max-width: 24px;
        }
    }
}

.btn-test-begin{
    padding: 0.3em 4em;
    font-size: 1.2rem;
}
.background-white{
    background: white !important;
}
.h-100{
    height: 100% !important;
}

.countdown-clock{
    margin-top: 1em;
    .label{
        display: block; 
        width: 100%; 
        font-weight: bold; 
        font-size: 1.2rem;
        margin-bottom: 0.5em;
    }
    .timer{
        width: 100%;
        .clock-pallet{
            margin: 0 0.5em;
            .left-time-value{
                margin-bottom: 0.5em;
                background: #f7d05e;
                color: #3a0c0c;
                padding: 0.3em 0.5em;
                border-radius: 0.2em;
                font-weight: bold;
            }
            .left-time-label{
                margin: 0;
                color: #666;
            }
        }
    }
}

.s1-v * {
    font-family: 'Open Sans';
    font-style: normal;
}

.s1-v {
    background: #F5F3F7;
    height: 100vh;
}

.s1-v .header {
    border-bottom: 1px solid #d9d1e081;
    display: flex;
    height: 60px;
    background: #fff;
    align-items: center;
}

.s1-v .header div a button {
    padding: 0px 16px;
    height: 40px;
    background: linear-gradient(0deg, #2CABF5, #2CABF5), #FFFFFF;
    border-radius: 24px;
    font-weight: 600;
    font-size: 20px;
    border: none;
    line-height: 40px;
    cursor: pointer;
    color: #FFFFFF;
}

.s1-v .header div {
    padding: 0px 32px;
    display: flex;
    margin-right: 16px;
    align-items: center;
    justify-content: center;
    height: 100%;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.2);
}

.s1-v .header p {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    letter-spacing: 0.01em;
    color: #323C47;
}

.s1-v .header>a {
    margin-left: auto;
    margin-right: 24px;
}

.s1-v .header>a button {
    background: #FFB3B3;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    height: 40px;
    cursor: pointer;
    letter-spacing: 0.01em;
    border: none;
    padding: 0px 16px;
    color: #A20000;
}

.s1-v .header a button:focus,
.s1-v>button:focus,
.s1-v>button {
    outline: none;
    border: none;
    cursor: pointer;
}

.s1-v .height {
    border-radius: 8px;
    display: flex;
    padding: 40px 32px 0px;
}

.s1-v .height>div {
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.08), -1px -2px 16px rgba(0, 0, 0, 0.08);
    min-height: calc(100vh - 250px);
    padding: 16px;

    border-radius: 8px;
    background: #fff;
}

.s1-v .height>div.h1-gg {
    height: calc(100vh - 180px);

}

.s1-v .height>div.name_details {
    width: 350px;
    margin-left: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.s1-v .height>div.name_details .br {
    height: 44px;
}

.name_details>img {
    width: 146px;
    height: 143px;
}

.name_details>p {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    margin-bottom: 12px;
    color: #979797;
}

.name_details>span {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.01em;
    color: #323C47;
}

.s1-v>button {
    background: #0097F9;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    width: 357px;
    height: 40px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    margin: 25px auto;
    display: block;
    color: #FFFFFF;

}

.s1-v .height>div:not(.name_details) {
    width: calc(100% - (350px + 24px));

}

@media only screen and (max-device-width: 850px) {
    .s1-v {
        height: auto;
    }

    .s1-v .header {
        height: auto;
        flex-wrap: wrap;
        padding: 8px;
        border-bottom: 4px solid #d9d1e081;
    }



    .s1-v .header div {
        display: inline;
        width: auto;
        box-shadow: none;


    }

    .s1-v .header>a {
        margin-right: 0px;
    }

    .s1-v .header p {
        width: calc(100% - 155px);
        margin-bottom: 4px;
    }

    .s1-v .height {
        padding: 8px;
        height: auto;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .s1-v .height>div:not(.name_details),
    .s1-v .height>div.name_details {
        width: 100%;
        padding: 8px;
        margin-bottom: 8px;
        height: auto;
    }
    .s1-v>button {
        width: 80%;
    }

    .s1-v .height>div.name_details {
        margin-left: 0px;
    }
}

.name_details h1 {
    color: red;
    font-size: 24px;
    font-weight: 300;
}

.name_details h6 {
    color: #888;
    font-size: 16px;
    font-weight: 300;
    margin-top: 12px;
}

.name_details>button {
    background: #0097F9;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    width: 257px;
    height: 40px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    margin: 25px auto;
    display: block;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
}
.name_details>button:focus {
    outline: none;
}
.h1-gg h1 {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.01em;
    padding: 0px 24px;
    color: #323C47;
    margin: 0px 0px 16px;
}
.h1-gg>div:not(.flex) {
  
    height: calc(100vh - 500px);
  
   
    border-bottom: 1px solid #D9D9D9;
}
.h1-gg>div:not(.flex)>div{
    height: calc(100vh - 500px);
    overflow-y: auto;
    padding-right: 16px;
    margin: 0px 16px 0px 24px;
}
.h1-gg>div>div::-webkit-scrollbar {
    border-radius: 8px;
    background: rgba(196, 196, 196, 0.4);
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.08);
    width: 10px;
}
.h1-gg>div>div::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, rgba(0, 151, 249, 0.4) -5.17%, rgba(0, 106, 175, 0.4) 106.03%);
    border-radius: 8px;
}

.h1-gg>div.flex {
    display: flex;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    /* or 150% */

    margin: 16px 24px;
    color: #000000;

}

/* Checkbox */

.checkbox__control {
display: inline-grid;
width: 19px;
height: 19px;
border-radius: 0.1em;
cursor: pointer;
border: 1px solid #989292;
border-radius: 4px;
}
.checkbox__input {
display: grid;
grid-template-areas: "checkbox";
}
.checkbox__control svg {
color: #119CF1;
transition: transform 0.1s ease-in 25ms;
transform: scale(0);
padding: 2px;
stroke-width: 4px;
transform-origin: bottom left;
}
​
.checkbox {
display: initial;
margin: 0px 8px 0px 0px;
}
​
.checkbox__input input {
opacity: 0;
width: 0em;
margin: 0px;
height: 0em;
}
.checkbox__input input:checked+.checkbox__control svg {
transform: scale(1);
}

.inputValue{
    
    font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
letter-spacing: 0.01em;

color: #323C47;

}