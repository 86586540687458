#exampleModal .modal-dialog,   #exampleModal2 .modal-dialog {
    width: 478px;
    margin: 0px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.modal-body-sm .headerv {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.01em;
    /* table_black */
    color: #323C47;
    display: flex;
    padding: 21px 21px 0px;
    align-items: center;
}

.modal-body-sm .headerv img {
    width: 42px;
    height: 42px;
    margin-right: 24px;
}

.modal-body-sm .headerv button.close img {
    width: 14px;
    height: 14px;
    margin-right: 0px;
}

.modal-body-sm .headerv button.close {
    margin-left: auto;
    position: relative;
    top: -12px;
    border: none;
    background-color: none;
    background: none;
}

.modal-body-sm>p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* or 138% */
    padding: 16px 32px 0px;
    letter-spacing: 0.01em;

    color: #393939;

}

.modal-body-sm>span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */
    padding: 0px 32px;
    letter-spacing: 0.01em;

    color: #F30F0F;

}
.modal-content{
    border: none;
}

.modal-body-sm>button {
    background: #109CF1;
    border: 1px solid #109CF1;
    /* accent button shadow */
    font-weight: 700;
    font-size: 13px;
    height: 40px;
    width: 414px;
    cursor: pointer;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    /* white */
    margin: 24px auto 32px;
    color: #FFFFFF;
    display: block;
    box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
    border-radius: 4px;
}

button:focus {
    outline: none;
}
#exampleModal .modal-dialog *,   #exampleModal2 .modal-dialog * {
    font-family: 'Open Sans';
    font-style: normal;
    
}