.prev {
    background: none;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 4px 0px 0px 4px;
    height: 34px;
    margin: 0;
    margin-top: -1px;
}

.next {
    height: 36px;
    background: none;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 0px 4px 4px 0px;
    margin: 0;
    margin-top: -1px;
}

button {
    border: 0;
    color: #777;
    margin: 0 8px;
}

.w-357 {
    /* Height will Dynamic accoring to panel open and close */
    display: flex;
    justify-content: center;
    margin-left: 2px;
    width: 80%;
}

.w-357>div.scr {
    /*   display: flex; */
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    letter-spacing: 0.01em;
    overflow-y: auto;
    color: #323C47;
    width: calc(50% - (168px + 17px + 17px + 8px));
    border: 1px solid #E8E8E8;
    border-radius: 4px;
}

.w-357>div::-webkit-scrollbar {
    display: none;
}

.w-357 div.box-sm {
    width: 184px;
}

.w-357 div.box-sm:not(:last-child) {
    border: 1px solid #E8E8E8;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}

.w-357 div.box-sm,
.w-357 div.box-xs {
    height: 36px;
    background: #FFFFFF;
    margin-bottom: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.w-357 div.scr>div {
    display: flex;
    min-width: max-content;
}

.w-357 div.box-xs {
    border: 1px solid #E8E8E8;
    border-radius: 4px 0px 0px 4px;
    height: 36px;
    min-width: 17px;
}

.w-357 div.box-sm.selected {
    border-bottom: 3px solid #2CABF5;
    padding-top: 3px;
    position: relative;
}

.uldiv {
    display: flex;
    list-style: none;
    max-width: 100%;
    width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-left: 0px;
    padding-bottom: 16px;
    background-color: #FFFFFF;
    background-clip: content-box;

    &::-webkit-scrollbar {
        background: transparent;
        /* make scrollbar transparent */
        -webkit-appearance: none;
        width: 0;
        height: 0;
    }
}

.lidiv {
    cursor: pointer;
    padding: 8px;
    margin: 0 0px;
    white-space: nowrap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #323C47;
    background: #FFFFFF;
    border-left: 1px solid #E8E8E8;
    border-right: 1px solid #E8E8E8;
    padding-left: 65px;
    padding-right: 65px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.lidivSelected {
    padding: 8px;
    border-radius: 4px;
    margin: 0 1px;
    white-space: nowrap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #323C47;
    width: 200px;
    background: #FFFFFF;
    border-left: 1px solid #E8E8E8;
    border-right: 1px solid #E8E8E8;
    border-bottom: 3px solid #2CABF5;
    padding-top: 3px;
    position: relative;

}

.lidivSelectedSpan {
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.lidivSelected::after {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    content: ' ';
    position: absolute;
    border-top: 12px solid #2CABF5;
    bottom: -15px;
    margin-left: -10px;
    overflow: auto;
}