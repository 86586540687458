
.flatLine{
    width: 93.5%;
    border-top: 1px solid #E8E8E8;
    height: 0;
    margin-left: 24px;
    // transform: rotate(90deg);
}
.stateLineIn {
    width: 30px;
    border-top: 1px solid #d9d9d9;
    height: 0;
    transform: rotate(90deg);
}
.s1-v .reg-height {
    border-radius: 8px;
    /*    height: calc(100vh - 150px); */
    display: flex;
    padding: 40px 32px 0px;
}

.s1-v .reg-height>div {
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.08), -1px -2px 16px rgba(0, 0, 0, 0.08);
    min-height: calc(100vh - 250px);
    padding: 16px;
    padding-right: 0px;
    border-radius: 8px;
    background: #fff;
}

.s1-v .reg-height>div.h1-gg {
    height: calc(100vh - 200px);

}

.s1-v .reg-height>div.name_details .br {
    height: 44px;
}

.s1-v .reg-height>div {
    width: calc(100%);
    margin-top: 42px;

}

.labeldiv {
    width: calc(50% - 32px);
    display: inline-block;
    margin-right: 25px;
    margin-top: 20px;
    background: #FFFFFF
}

.labeldiv input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #C4CACE;
    border-radius: 2px;
    margin-top: 5px;
    padding-left: 12px;
    height: 35px
}

.goBackButton {
    background: #FFFFFF;
    border: 1px solid #0097F9;
    border-radius: 4px;
    width: 357px;
    height: 40px;
}

.goBacklabel {
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #0097F9;
}

.proccedButton {
    background: #0097F9;
    border-radius: 4px;
    width: 357px;
    height: 40px;
    border: none;
}

.proccedlabel {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #FFFFFF;

}

.labelname {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #1A2334;
}

::placeholder {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #8E939A;
    align-items: center;
}

.h1-gg h1 {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.01em;
    padding: 0px 24px;
    color: #323C47;
    margin: 0px 0px 16px;
}

.h1-gg>div:not(.flex) {
    height: calc(100vh - 350px);
    border-bottom: 0px;
}

.h1-gg>div:not(.flex)>div {
    height: calc(100vh - 286px);
    overflow-y: auto;
    padding-right: 0px;
    margin: 0px 8px 0px 24px;
}

.h1-gg>div>div::-webkit-scrollbar {
    border-radius: 8px;
    background: rgba(196, 196, 196, 0.4);
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.08);
    width: 10px;
}

.h1-gg>div>div::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, rgba(0, 151, 249, 0.4) -5.17%, rgba(0, 106, 175, 0.4) 106.03%);
    border-radius: 8px;
}