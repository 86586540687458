@import url('https://fonts.googleapis.com/css?family=Quicksand:500');

.row {
  font-family: 'Roboto', Helvetica, sans-serif;
}

.box {
  border: 1px solid #c1bcbc;
  height: 60px;
  width: 80%;
  border-radius: 15px;
  margin: 0 auto;
  margin-top: -5px;
  display: flex;
}

@media only screen and (max-width: 768px) {

  body,
  html {
    font-size: 16px !important;
  }

  .col-xs-6 {
    width: 50% !important;
  }

  /* .col-xs-12{
    width: 100%;
  } */
  .list-card {
    margin: 5px 0px !important;
  }

  .text-left-xs {
    text-align: left !important;
    padding-left: 1rem !important;
  }

  .hidden-xs {
    display: none;
  }

  .progress {
    width: 120px !important;
    height: 120px !important;
  }
}

.m-auto {
  margin: auto;
}

.m-t-2e {
  margin-top: 1em;
}

.m-b-2e {
  margin-bottom: 1em;
}

.m-2e {
  margin: 2em;
}

.p-b-2e {
  padding-bottom: 2em;
}

.f-w-600 {
  font-weight: 600;
}

.border-t-1 {
  border-top: 1px solid #c1bcbc;
}

.border-b-1 {
  border-bottom: 1px solid #c1bcbc;
}

.border-l-1 {
  border-left: 1px solid #c1bcbc;
}

.border-r-1 {
  border-right: 1px solid #c1bcbc;
}

.border-b-3 {
  border-bottom: 3px solid #c1bcbc;
}

.height-200 {
  height: 200px;
}

.height-400 {
  height: 400px;
}

.question {
  display: inline;
}

.que-source {
  float: right;
  background: #f5f5f5;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 14px;
}

.ans-ul {
  list-style: none;
  padding-left: 0;
}

.ans-li {
  margin-left: 3em;
}

.ans-tick {
  float: left;
  padding-top: 0.3em;
}

.ans-text {
  margin-left: 2em;
  display: flex;
}

.ans-correct {
  margin-left: 2em;
  display: flex;
}

.active {
  background: #1f98ff;
  color: #fff;
}

.list-card {
  box-shadow: rgb(240, 240, 240) 0pt 0pt 8pt 0pt;
  font-family: Nunito;
  background: rgb(255, 255, 255);
  padding: 1em 1em;
  margin: 1em 0px;
  display: flex;
}

.list-card.active {
  background: #e9fcff;
  color: #333;
}

.hide {
  display: none;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.toggle-button {
  margin: 5px 0;
  border-radius: 20px;
  background-color: #83b7d6;
  border: 2px solid #5ac1c1;
  height: 24px;
  cursor: pointer;
  width: 50px;
  position: relative;
  display: inline-block;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
}

.toggle-button span {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 100%;
  width: 26px;
  height: 26px;
  background-color: white;
  float: left;
  margin: -3px 0 0 -3px;
  border: 2px solid #D0D0D0;
  transition: left 0.3s;
}

.toggle-button-selected {
  background-color: #83B152;
  border: 2px solid #7DA652;
}

.toggle-button-selected span {
  left: 26px;
  top: 0;
  width: 26px;
  height: 26px;
}

.test-box {
  border: 1px solid #c1bcbc;
  height: 60px;
  width: 100%;
  border-radius: 15px;
  margin: 0 auto;
  display: flex;
  font-size: 12px;
}

.filter-box {
  border: 1px solid #c1bcbc;
  padding: 0 0.5em;
  cursor: pointer;
}

/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/

.progress {
  width: 150px;
  height: 150px;
  background: none;
  position: relative;
}

.progress::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid #eee;
  position: absolute;
  top: 0;
  left: 0;
}

.progress>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 6px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}

.progress .progress-value {
  position: absolute;
  top: 0;
  left: 0;
}

/*
  *
  * ==========================================
  * FOR DEMO PURPOSE
  * ==========================================
  *
  */

// body {
//   background: #ff7e5f;
//   background: -webkit-linear-gradient(to right, #ff7e5f, #feb47b);
//   background: linear-gradient(to right, #ff7e5f, #feb47b);
//   min-height: 100vh;
//   font-size: 16px !important;
// }

.rounded-lg {
  border-radius: 1rem;
}

.text-gray {
  color: #aaa;
}

div.h4 {
  line-height: 1rem;
}

.chart-class {
  cursor: pointer;
}

.border {
  border-right: 1px solid gray;
}

.size-15 {
  font-size: 15px !important;
}

.stud-answer {
  word-break: break-all;
  display: contents;
  img {
    max-width: 250px !important;
    min-width: 250px !important;
  }
}
.donutchart{
  svg{
    g{
      text{
        margin-bottom: 12px;
      }
    }
  }
}