.welcome-box{
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.95);
    .logo{
        width: 100%;
        display: flex;
        img{
            margin: auto;
            max-width: 200px;
            padding-top: 5em;
        }
    }
}