.testCountNumberDiv {
    display: flex;
    flex-wrap: wrap;

}

.subjectTitleDiv {
    width: 100%;
    background-color: #e4f3ff;
    margin-top: 5px;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.subjectTitle {
    margin-left: 10px;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;

}

.subjectName {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    margin-left: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-bottom: 0px;
    width: 200px;
    align-items: center;
    margin-bottom: 0px;
}