
.loader-box{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(#fff, #fff);
}

/* Animation */
@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

/* Variables */
$loader-size: 100px;

/* Loading Icon */
.loading {
    width: $loader-size;
    height: $loader-size;
    margin-top: 0em;
    &__ring {
        position: absolute;
        width: $loader-size;
        height: $loader-size;

        &:first-child {
            transform: skew(30deg,20deg);
        }

        &:last-child {
            transform: skew(-30deg,-20deg) scale(-1, 1);

            svg {
                animation-delay: -0.5s;
            }
        }

        svg{
            animation: rotate 1s linear infinite;
            fill: #6ca94c;
        }
    }
}


.overlay-wrapper{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    text-align: center;
    .text-box{
        margin: auto;
    }
}

.overlay-white{
    color: #000;
    opacity: 1;
    background: #fff;
    z-index: 6;
    .btn-close{
        box-shadow: -2pt -2pt 4pt 0pt #90b9e8;
        padding: 0.2em 2em;
    }
}


.loader{
    width: 100px;
    height: 100px;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
}

/* LOADER 7 */

#loader-7{
    -webkit-perspective: 120px;
    -moz-perspective: 120px;
    -ms-perspective: 120px;
    perspective: 120px;
}

#loader-7:before{
    content: "";
    position: absolute;
    left: 25px;
    top: 25px;
    width: 50px;
    height: 50px;
    background-color: #0081eb;
    animation: flip 1s infinite;
}

@keyframes flip {
    0% {
        transform: rotate(0);
    }

    50% {
        transform: rotateY(180deg);
    }

    100% {
        transform: rotateY(180deg)  rotateX(180deg);
    }
}
  