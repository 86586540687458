.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9;
}

.box2 {
    position: relative;
    width: 35%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: 5vh;
    /* margin-top: calc(100vh - 85vh - 20px); */
    background: #fff;
    border-radius: 4px;
    border: 1px solid #999;
    overflow: auto;

    padding: 45px;
}

.box2::-webkit-scrollbar {
    display: none;
  }
.questionStatusInline{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
}
.statusImgInline{
    display: flex;
    flex-direction: row;

}
.textStatusFormat{
     margin-left: 10px;
     font-size: 17px;
     font-weight: 500;
     color: "#000000";
}
.numberFormat{
    font-size: 18px;
    font-weight: 700;
    color: "#000000";
}



.modal-body-md {
    width: 578px;
    margin: 0px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: fixed;
    max-width: none;
    background-color: #fff;
}


.modal-body-md .headerv {

    display: flex;
    padding: 32px 24px 0px;
    align-items: center;
}

.modal-body-md .headerv h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.01em;
    /* table_black */
    color: #323C47;
}

.modal-body-md .headerv h2 p {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    /* identical to box height, or 169% */
    margin-bottom: 0px;
    letter-spacing: 0.01em;

    color: #393939;

}

.modal-body-md .headerv img {
    width: 42px;
    height: 42px;
    margin-right: 24px;
}

.modal-body-md .headerv button.close img {
    width: 14px;
    height: 14px;
    margin-right: 0px;
}

.modal-body-md .headerv button.close {
    margin-left: auto;
    position: relative;
    top: -32px;
}

button:focus {
    outline: none;
}

.modal-body-md>.display {
    display: flex;
    padding: 32px 24px;
    justify-content: space-between;
}

.modal-body-md>.display button {
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    cursor: pointer;
    border-radius: 4px;
    height: 40px;
    width: 251px;
}

.modal-body-md>.display button:first-child {
    color: #109CF1;
    background: #fff;
    border: 1px solid #109CF1;
    filter: drop-shadow(0px 4px 10px rgba(16, 156, 241, 0.14));
}

.modal-body-md>.display button:last-child {
    background: #109CF1;
    border: 1px solid #109CF1;
    /* accent button shadow */
    color: #fff;
    box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);

}

.tt-8 {
    margin-top: 8px;
    padding: 0px 8px;
}

.tt-8>div {
    display: flex;
    align-items: center;
    padding: 8px 24px;
}

.tt-8 span {
    display: block;
    margin-left: auto;
}

.tt-8 img {
    margin-right: 32px;
}