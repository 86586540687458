.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9;
}

.mainboxQuestionUpload {
    position: relative;
    width: 50%;
    margin: 0 auto;
    height: auto;
   height: 32%;
    /* margin-top: calc(100vh - 85vh - 20px); */
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
    margin-top: 5%;
}