.option-box {
    margin: 0.5em 0em;
    display: flex;
    align-items: center;

    label {
        display: flex;
        margin: 5px;
    }

    .option-label {
        margin: 0;
        margin-left: 0.5em;
        margin-right: 0.5em;
        display: flex;
        align-items: center;

    }

    .option-label #lable p {
        margin-bottom: -2px;
    }

    .option-label p {
        margin-bottom: -2px;
    }

    .option-text {
        display: inline-block;
    }
}

.removepmargin p {
    margin-bottom: -1px;
}

.both-side-options {
    .option-box {
        display: flex;
        margin: 0.5em 0;

        .left-side-option {
            width: 50%;
            float: left;
            margin: 0;

            .option-no {
                width: auto;
                margin-right: 0.5em;
                display: inline-block;
            }
        }

        .right-side-option {
            width: 50%;
            float: left;
            margin: 0;

            .option-no {
                width: auto;
                margin-right: 0.5em;
                display: inline-block;
            }
        }
    }
}

.answer-option-box {
    margin: 0.6em 0;
    border: 1pt solid #aaa;
    border-radius: 0.2em;
    background: #f5f5f5;
    display: block;
    width: fit-content;

    .option-no {
        padding: 0.4em 0.8em;
        background: #fff;
        display: inline-block;
        border-right: 1pt solid #ddd;
    }

    .option-select {
        padding: 0.4em 0.8em;
        display: inline-block;

        input {
            vertical-align: middle;
        }

        label {
            margin: 0;
            margin-left: 0.4em;
            vertical-align: middle;
        }
    }
}