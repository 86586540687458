.containerQuestion {
    width: 80%;
    height: 50vh;
    overflow: scroll;
}

.containerQuestion::-webkit-scrollbar {
    display: none;
}

.Questiondiv {
    width: 100%;
    border-radius: 5px 5px 5px 5px;
    border: 2px solid #EDEAEA;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
}

.GreenLablecount {
    height: 23px;
    width: 23px;
    background-color: green;
    border-radius: 5px;
    text-align: center;
    align-items: center;
}

.RedLablecount {
    height: 23px;
    width: 23px;
    background-color: red;
    border-radius: 5px;
    text-align: center;
    align-items: center;
}

.BlackLablecount {
    height: 23px;
    width: 23px;
    background-color: black;
    border-radius: 5px;
    text-align: center;
    align-items: center;
}

.QuestionText {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #454545;
}

.WrongOption {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: red;
    margin-left: 10px;

}

.CurrectOption {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: green;
    margin-left: 10px;
}

.NormalOPtion {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: black;
    margin-left: 10px;

}

.OptionDiv {
    display: flex;
    flex-direction: row;
    margin-top: 18px;
}

.MarksDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #454545;
}
.CurrectAnswer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: green;
}
.YourAnswer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: red;
}