.resultcontainer {
    height: 100vh;
    width: 100%;

}

.topHeader {
    margin-left: 3rem;
    margin-right: 3rem;
}


.borderLine {
    height: 0px;
    border-bottom: 2px solid #D9D1E0;
}

.subjectHeader {
    margin-left: 3rem;
    margin-right: 3rem;
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #D9D9D9;
}

.subjectNamediv {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #323C47;
}


.signInTitle {
    font-weight: 400;
    font-size: 0.83rem;
    line-height: 18px;
    color: #578CC7;
    margin-bottom: 0px;
    text-align: right;
}

.signValue {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.01em;
    color: #323C47;
    text-align: right;
}

.obtainpercentage {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.063rem;
    color: #1BC19A;

}

.totalpercentage {
    font-weight: 600;
    font-size: 1rem;
    line-height: 2.063rem;
    color: #1BC19A;
}

.markobtain {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #334D6E;
}

.subjectname {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 25px;
    /* identical to box height */
    width: 480px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0.01em;
    color: #323C47;

}



.levelstyle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #323C47;

}

.levelvaluestyle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #323C47;

}

.headerdiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.detailsdiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 4px;
}

.buttondiv {
   
    height: 40px;
    background: #0097F9;
    border-radius: 4px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    padding: 8px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
}

.resultSection{
    /* box-shadow: 2px 2px #FFFFFF; */
    border-radius: 8px;
   padding-left: 24px;
display: flex;
justify-content: space-between;
box-shadow: 1px 2px 9px #D9D9D9;

}
.awardDiv{
    background: rgb(244, 250, 255);
    width: 25%;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 36px;
    padding-bottom: 36px;
}

.reportcontainer {
    width: 100%;
    display: flex;
    justify-content: center;

}

.reportmaindiv {
    width: 80%;
    padding: 15px;
    display: flex;
    flex-direction: row;
}


.testdetail {

    width: 50%;

}

.markdetails {
    width: 50%;
}

.buttonStyle {
    cursor: pointer;
    width: calc(100%/3 - 10px);
    /* width: 20%; */
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: rgba(51, 77, 110, 0.9);
    text-align: center;
}

.buttonStylesubject {
    cursor: pointer;
    /* width: 40%; */
    width: calc(100%/3 - 10px);
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: rgba(51, 77, 110, 0.9);
    text-align: center;
}

.onClickButtonsubjeact {
    cursor: pointer;
    /* width: 40%; */
    width: calc(100%/3 - 10px);
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #1B62E6;
    border-bottom-color: #1B62E6;
    border-bottom: 2px solid;
    align-items: center;
    text-align: center;
    padding-bottom: 5px;

}

.onClickButton {
    cursor: pointer;
    width: calc(100%/3 - 10px);
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #1B62E6;
    border-bottom-color: #1B62E6;
    border-bottom: 2px solid;
    align-items: center;
    text-align: center;
    padding-bottom: 5px;

}