.timeHeader {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.timerDiv {
    display: flex;
    width: 220px;
    height: 35px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    justify-content: space-between;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
}

.timerun {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    border: 1px solid #28A745;
    box-shadow: 1px 1px 1px  #03C250;
    border-radius: 4px;
}
.timerunalert {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    border: 1px solid #F30F0F;
    box-shadow: 1px 1px 1px  #F30F0F;
    border-radius: 4px;
}
