.topaddbutton {
    display: none;
}

.showsubmitbutton {
    display: none;
}


.questionheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    margin: 8px;
    border-top: 2px solid rgba(0, 0, 0, 0.08);
    padding-bottom: 10px;
}

.subjectSelected {
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    width: 150px;
    border-radius: 2px;
    height: 36px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.langOption {
    color: #334D6E;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    /* identical to box height */
    width: 80%;
    border: 0;
    letter-spacing: 0.01em;
    align-items: center;
}

.questionoptionfield {
    height: 90%;
    background: #FFFFFF;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    margin-right: 12px;
}





@media screen and (max-width: 870px) {
    .topaddbutton {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        border-bottom: "2px solid red";

    }

    .showsubmitbutton {
        background-color: rgb(40, 167, 69);
        margin-top: 5px;
        outline: none;
        border: 0;
        height: 34px;
        color: #fff;
        font-family: Helvetica;
        font-size: 14px;
        display: inline;
    }

    .clearresponsebtn {
        display: none;
    }

    .markforreview {
        display: none;
    }
}