.maincontainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: "100%";
    overflow: hidden;
    background: #F5F3F7;


}

.headerpanel {
    height: 61px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    background: #FFFFFF;


}

.subjectTypeDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subjectType {
    font-size: 16px;
    font-weight: bold;
    font-family: sans-serif;
    white-space: nowrap;
    width: 480px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.buttontext {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
}

.mainbody {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 8px;
    /* height: 500px; */
    height: calc(100% - 80px);
}

.questionfield {
    /* height: 95vh; */
    width: calc(100% - 364px);
    display: flex;
    flex-direction: column;
}

.informationfield {
    display: flex;
    flex-direction: column;
    width: 357px;
}

.legendsmain {
    padding: 16px;
}


.studentInformation {
    display: flex;
    flex-direction: row;
    padding: 8px;
    align-items: center;

}

.testcountnumber {
    height: 60%;
    border-top: 2px solid #e3e3e3;
    border-bottom: 2px solid #e3e3e3;
    overflow: auto;
}

.testcountnumber::-webkit-scrollbar {
    border-radius: 5px;
    background: rgba(196, 196, 196, 0.4);
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.08);
    width: 6px;
}

.testcountnumber::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, rgba(0, 151, 249, 0.4) -5.17%, rgba(0, 106, 175, 0.4) 106.03%);
    border-radius: 5px;
}

.testcountimgdiv {
    padding: 10px;
}

.studentDetailsblock {
    background: #FFFFFF;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.showInstruction {
    margin-left: 10px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    height: 36px;
    display: flex;
    align-items: center;
    padding-right: 16px;

}

.showcalculator {
    margin-left: 10px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    height: 36px;
    display: flex;
    align-items: center;
    padding-right: 16px;
}

/*  */
.stateLine {
    width: 30px;
    border-top: 1px solid #E8E8E8;
    height: 0;
    transform: rotate(90deg);
}

.legendscountImg {
    width: 34px;
    height: 34px;

}

.legends {
    display: inline-flex;


}

.logobutton {
    padding: 0px 16px;
    height: 40px;
    background: linear-gradient(0deg, #2CABF5, #2CABF5), #FFFFFF;
    border-radius: 24px;
    font-weight: 600;
    font-size: 20px;
    border: none;
    line-height: 40px;
    cursor: pointer;
    color: #FFFFFF;
}

.timeHeader {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.timerDiv {
    display: flex;
    width: 220px;
    height: 35px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    justify-content: space-between;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
}

.timerun {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    border: 1px solid #28A745;
    filter: drop-shadow(0px 0px 4px #03C250);
    border-radius: 4px;
}

.submitbutton {
    background: #28A745;
    border-radius: 4px;
    margin-top: 5px;
    outline: none;
    border: 0;
    height: 34px;
    color: #fff;
    border-radius: 4px;
    width: calc(100% - 10px);
    margin-top: 20px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.01em;

    color: #FFFFFF;

}

.firstlegends {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    /* justify-content: space-around; */
    /* justify-content: space-between; */
}

.legendsImg {
    width: 24px;
    height: 24px;
}

.legendstext {
    font-size: 14px;
    font-family: Helvetica, sans-serif;
    font-weight: normal;
    margin-left: 5px;
}

.legend-value {
    position: absolute;
    font-size: 10px;
    display: flex;
    width: 24px;
    height: 24px;
    color: #fff;
    text-shadow: 0pt 0pt 2pt #000;
    margin-top: -20px;
    margin-left: 5px;

}



.clearresponsebtn {
    margin-left: 5px;
    margin-right: 5px;
    outline: none;
    padding: 5px 12px 5px 12px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #323C47;
    margin-top: 20px;

}

.markforreview {
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
    outline: none;
    padding: 5px 12px 5px 12px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height */
    margin-top: 20px;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
    background: #FBB142;
    border-radius: 4px;
}

.saveandnext {
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
    outline: none;
    padding: 5px 12px 5px 12px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height */
    margin-top: 20px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #0097F9;
    border-radius: 4px;
}

/* .submitbutton{
    display: none;
} */
.showQuesionfield {
    display: inline;
}



.dropbutton {
    display: none;
}

.widthSize {
    display: flex;
    flex-direction: column;
    width: 0%;
    border-left: 2px solid #e3e3e3;
}

.hideContain {
    display: none;
}

.drower {
    position: fixed;
    top: 50%;
    background-color: #0097F9;
    height: 40px;
    border-radius: 5px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -19px;
    cursor: pointer;
}

.right {
    width: 10px;
    height: 10px;
    transition: .5s;
    float: left;
    box-shadow: -2px 2px 0 #FAFAFA;
    margin-left: -9px;
}

.left {
    width: 10px;
    height: 10px;
    transition: .5s;
    float: left;
    box-shadow: -2px 2px 0 #FAFAFA;
    margin-left: 8px;
}

.right {
    transform: rotate(-135deg);
}

.left {
    transform: rotate(45deg);
}


@media screen and (max-width: 870px) {
    .hideField {
        display: none;
    }

    .informationfield {
        width: 100%;
    }

    .questionfield {
        width: 100%;
        /* height: 90vh; */
    }

    .showcalculator {
        display: none;
    }

    .logoImg {
        display: none;
    }

    .showInstruction {
        display: none;
    }

    .submitbutton {
        display: inline;
    }

    .dropbutton {
        display: inline;
    }
}