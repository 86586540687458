
.cardbox{
    border: 1px solid #ddd;
    box-shadow: 1px 1px 2px #ddd;
    border-radius: "5px";
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin: 10px;
    
    width: calc(50% - 44px);
    height: 300px;
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 16px;
    border: 1px solid #cccc;
    background: #FFFFFF;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0px 5px 5px 5px;
}
.media{
    height: 270px;
    width: 100%;
}