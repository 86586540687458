.questionInfodiv {
    height: 60px;
    width: calc(100% - 28px);
    margin-right: 11px;
    margin-left: 17px;
    border-bottom: 1px solid #D9D9D9;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.questionNumLebel {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: 0.01em;
    color: #323C47;
    margin-bottom: 0px;
}

.stateLine {
    width: 30px;
    border-top: 1px solid #E8E8E8;
    height: 0;
    transform: rotate(90deg);
}

.markLebel {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #323C47;
    margin-left: 15px;
    margin-bottom: 0px;
}

.postiveMark {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #03C250;
    margin-left: 10px;
    margin-bottom: 0px;
}

.negativeMark {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #F30F0F;
    margin-left: 10px;
    margin-bottom: 0px;
}

.questionTypeLabel {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #323C47;
    margin-left: 15px;
    margin-bottom: 0px;
}

.questionTypeValue {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #323C47;
    margin-left: 10px;
    margin-bottom: 0px;
}

.questionshowField{
height: 50vh;
margin-top: 36px;
padding-left: 48px;
padding-right: 48px;
overflow-y: auto;
margin-right: 4px;
word-break: break-all;
}

.questionshowField::-webkit-scrollbar {
    border-radius: 5px;
    background: rgba(196, 196, 196, 0.4);
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.08);
    width: 6px;
}

.questionshowField::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, rgba(0, 151, 249, 0.4) -5.17%, rgba(0, 106, 175, 0.4) 106.03%);
    border-radius: 5px;
}