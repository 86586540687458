.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9;
}

.mainbox {
    position: relative;
    width: 50%;
    height: 60vh;
    max-height: 70vh;
    margin-top: 20vh;
    background: #fff;
    border: 1px solid #999;
    overflow: auto;
    background: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
}

.previewmsg {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #1A2334;

}
.previewdiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #999;
}
.container {

    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }
  
  .responsive-iframe {
  width: 100%;
  height: 46%; 
  border-radius: 10px;
  }
  .responsive-iframe::-webkit-scrollbar {
    border-radius: 8px;
    background: rgba(196, 196, 196, 0.4);
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.08);
    width: 10px;
}

.responsive-iframe::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, rgba(0, 151, 249, 0.4) -5.17%, rgba(0, 106, 175, 0.4) 106.03%);
    border-radius: 8px;
}