.welcome-box{
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
    .logo{
        width: 100%;
        display: flex;
    }
 

