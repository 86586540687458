.calculator-div *{
	box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box; 
    font-family: Arial;
    vertical-align: middle;
}

#calculator_keyPad_Help{
    color: #000;
/*     background: url('../../../../../assets/images/Sprite.png') no-repeat -175px -13px;
 */    width: 54px;
	height: 25px;
	padding:1px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;  
    line-height: 20px;
    position: absolute;
    right: 79px;
    text-align: center;
    top: 7px;
    border-radius: 10px;
    opacity: 0.7;
    color: white;
    font-size: 15px;
    background: blue	
}
#calculator_keyPad_Help:hover{
/*     background: url('../../../../../assets/images/Sprite.png') no-repeat -175px -13px;
 */ 	width: 54px;
	height: 25px;
}
#keyPad_Helpback{
    color: #000;
/*     background: url('../../../../../assets/images/Sprite.png') no-repeat -175px -13px;
 */ 	width: 54px;
	height: 25px;
	padding:1px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;  
    line-height: 20px;
    position: absolute;
    right: 79px;
    text-align: center;
    top: 7px;
    border-radius: 10px;
    opacity: 0.7;	
}
#keyPad_Helpback:hover{
/*     background: url('../../../../../assets/images/Sprite.png') no-repeat -175px -13px;
 */ 	width: 54px;
	height: 25px;
}
.calc_min{
/*     background: url('../../../../../assets/images/Sprite.png') no-repeat -175px -13px;
 */ 	width: 16px;
	height: 3px;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
  
    line-height: 25px;
    position: absolute;
    right: 42px;
    text-align: center;
    top: 25px;
   

}
.calc_max{
/*     background: url('../../../../../assets/images/Sprite.png') no-repeat -175px -13px;
 */ 	width: 16px;
	height: 16px;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
   
    line-height: 25px;
    position: absolute;
    right: 42px;
    text-align: center;
    top: 12px;   
	
}
.hide{
 display:none;
}
.reduceWidth{
	width:200px !important;
}
.reduceHeader {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    width: 74px !important;
}
/*.calc_close:hover{
	background:#c0392b;
}*/
#mainContentArea{
margin-top:0px;
padding:10px;
}
.calc_container {
    background: none repeat scroll 0 0 #ffffff;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    padding: 0px;
    width: 463px;
    position: relative;
    box-shadow: -2pt 4pt 8pt #ddd;

}
#keyPad_Help{
   /* position: absolute!important;
    right: 125px;*/
}
.left_sec{
    float: left;
   /*  margin-right: 10px; */
   margin-top: 2%;
}
.right_sec{
    float: left;
    height:180px; 
}
/* KEY ROW */
.calc_row{
    display: block;
    overflow: hidden;
    margin-bottom: 5px;
}


/* KEY BUTTONS */
#keyPad a {
    background: #f1f1f1;
    border-color: #aaa;
    border-image: none;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px 1px 3px;
    color: #444;
    cursor: pointer;
    /*  display: block; */
    float: left;
    font-size: 12px;
    font-weight: bold;
    height: 28px;
    line-height: 20px;
    margin-right: 5px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    width: 35px;
    background: #bec2c3;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #ccc;
}
#keyPad a.min {
    font-size: 10.5px;
}
#keyPad a.last {
    margin-right: 0px;
}


#keyPad a:active {
    position: relative;    
    height: 25px;
    border-bottom: 1px solid #aaa;
}
#keyPad_btnEnter:active  {
	position:absolute !important;	
	bottom:17px !important;  
}
#keyPad a:hover  {
    background: #eaeaea;
}
.text_container {
   display:inline;
  /*  position: absolute; */
}
 .memoryhide {
  visibility: hidden;
  position: absolute;
  top: 52px;
  right:429px;
  bottom: 0;
  opacity: 0;
  transition: opacity 1s, visibility, 1s;
}
.memoryshow {
  visibility: visible;
  opacity: 1;
} 

/* INPUT/MEM TEXT BOXES*/
.keyPad_TextBox, .keyPad_TextBox1{
    background: none repeat scroll 0 0 #fff;
    border-color: #ccc;
    border-image: none;
    border-radius: 2px;
    border-style: solid;
    font-size: 30px;
    height: 40px;
    line-height: 30px;
    text-align: right;
    text-indent: 10px;
    vertical-align: middle;
    width: 434px;
    float: left;
    margin-bottom: 7px;
    padding-right:5px;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.01);
    background: #f7f7f7;	
	
}

.degree_radian{
    float: left;
    height: 25px;
    width: 80px;
    display: inline-block;
    font-size:9px;
    margin-top: 4px;
    vertical-align: middle;
	color: black;
}

#keyPad_btnDot{
	width:35px!important;
}
#keyPad_btn0{
	width:76px!important;
}
/* ARROWS */
.calc_arrows{
    font-size: 24px!important;
    /*line-height: 29px!important;*/
}

/* ENTER a: QUAD WIDTH */
#keyPad #keyPad_btnEnter{
    background: none repeat scroll 0 0 #2ecc71;
    border-color: #27ae60;
	bottom:17px;
    color: #fff;
    font-size: 20px;
	line-height: 47px;
	right:10px;
        *right:-6px;
    width: 35px;
    height: 53px;
	position:absolute;
    /* LINE-HEIGHT: 65PX; */
    FONT-SIZE: 20px;	
}
	
/* ZERO a: DOUBLE WIDTH  
#keyPad #keyPad_btn0{  
    width: 95px;  
}*/

/* BACKSPACE/CLEAR INPUT KEYS*/

#keyPad #keyPad_btnClr,
#keyPad #keyPad_btnInverseSign,
#keyPad #keyPad_btnAllClr{
    background: none repeat scroll 0 0 #e74c3c;
    color: #fff;
    border-color: #c0392b;
	width:35px!important;
    
}
#keyPad #keyPad_btnBack
{
background: none repeat scroll 0 0 #e74c3c;
    color: #fff;
    border-color: #c0392b;
	width:76px!important;
}	
/* SPECIAL FORMATTING FOR SUPER-SCRIPT KEYS */
#keyPad #keyPad_btnSquare,
#keyPad #keyPad_btnCube,
#keyPad #keyPad_btnExp,
#keyPad #keyPad_btnYpowX{ 
    font-size: 12px;
}

/* CLEAR BOTH */
.clear {clear:both;}

.superscript,.subscript  {
    font-size: 0.7em;
    position: relative;
    top: -0.5em;
}
.subscript {
   top: 0.5em;
}
/** new help changes **/
#helpContent
{
	
    height: 263px;
    margin-right: 6px;
    overflow: auto;
    padding: 10px;
	background: whitesmoke;
	border-radius: 10px;
}
#helptopDiv
{
	margin-bottom:5px;
	padding: 10px;
    color:#000;
    cursor:move;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
#helptopDiv img{
    margin-right: 10px;
    cursor: pointer;
}

.showCalculator, .hideCalculator{
    display: none;
    cursor: pointer;
    margin: 15px 3% 0 0;
    border: 1px solid #a0a0a0;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
}

.calculator-div{
	position: fixed;
    z-index: 1000;
    left: 540px;
    top: 227px;   
}
.mymodal{
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
}
.mBody{
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

.draggable-div {
    position: absolute;
    width: 150px;
    height: 100px;
    background-color: lightblue;
    border: 1px solid #ccc;
    cursor: grab;
    user-select: none;
  }