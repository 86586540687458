.msgFont{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
}
.msgTitle{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #000000;
    margin-top: 8px;
    margin-bottom: 8px;
}