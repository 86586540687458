.header{
    border-bottom: 1px solid #ddd;
    box-shadow: 0pt 0pt 2pt #eee;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 13vh;
    background: white !important;
    z-index: 9;
    .logo img{
        max-height: 48px;
        padding: 0.4em 0;
    }
    .test-name{
        font-size: 1rem;
        font-weight: bold;
        margin: auto;
    }
}

.pt-4 {
    padding-top: 2.5rem!important;
}
.panel-body{
    padding-top: 10vh;
    height: 100vh;
    .left-pallete{
        background: #fff;
        height: 100%;
        .test-sections-wrapper{
            background: #f5f5f5;
            border-bottom: 1px solid #ddd;
            .section-switch{
                border: 1px solid #fff;
                border-radius: 0;
                font-size: 0.85rem;
            }
            .section-switch.active{
                background: #07f;
                border: 1px solid #07f;
                color: #fff
            }
        }
        .test-details-wrapper{
            padding: 0.2em 0;
            border-bottom: 1px solid #ddd;
            font-size: 0.85rem;
        }
        .quesion-panel-wrapper{
            height: 80%;
            overflow-y: auto;
            padding: 1em 0;
            .passage-wrapper{
                padding-bottom: 0.5em;
                border-bottom: 1pt solid #ddd;
                margin-bottom: 1em;
                color: #000;
                font-size: 0.95rem;
                .passage-overflow-hidden{
                    max-height: 92px;
                    overflow: hidden;
                }
                .passage-overflow-allow{
                    max-height: none;
                }
            }
            .question-answer-wrapper{
                
                .question{
                    .question-number{
                        font-weight: bold;
                    }
                    .question-text{
                        font-size: 1.1rem;
                        margin-bottom: 0.8em;
                    }
                }
            }
        }
        .left-pallete-footer{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 0.5em;
            border-top: 1px solid #ddd;
            background: #f5f5f5;
            .btn{
                margin: 0.2em;
            }
        }
    }
    .left-pallete.active{
        flex: 0 0 100%;
        max-width: 100% !important;
    }
    .right-pallete{
        background: #fff;
        border-left: 1px solid #ddd;
        max-height: 100%;
        .user-profile{
            background: #fff;
        }
        .question-pallete{
            overflow-y: auto;
            max-height: 62%;
            padding-bottom: 10em;
            display: block;
            border-top: 1pt solid #ddd;
            .question-section-name{
                font-size: 0.9rem;
                padding: 0.2em 0.6em;
                margin: 0;
                background: #fafafa;
                color: #000;
            }
            .section-questions{
                padding: 0.2em 0.6em;
                .question-number-box{
                    width: 30px;
                    height: 30px;
                    display: inline-flex;
                    margin: 0.6em;
                    cursor: pointer;
                    span{
                        margin: auto;
                    }
                }
            }
        }
        .right-pallete-footer{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 0.5em;
            border-top: 1px solid #ddd;
            background: #f5f5f5;
            .legends-wrapper{
                .legend{
                    display: inline-flex;
                    font-size: 0.85rem;
                    padding: 0.3em 0;
                    img{
                        width: 24px;
                        height: 24px;
                        margin-right: 0.2em;
                    }
                    p{
                        margin: 0;
                    }
                    .legend-value{
                        position: absolute;
                        font-size: 10px;
                        display: flex;
                        // width: 24px;
                        // height: 24px;
                        color: #fff;
                        text-shadow: 0pt 0pt 2pt #000;
                       
                    }
                }
            }
        }
    }
    .right-pallete.hide{
        display: none !important;
    }
}
.bell {
    display: inline-block;
    position: relative;
    width: 48px;
    height: 42px;
    text-align: center;
    padding-top: 6px;
    cursor: pointer;
}
.bell img {
    width: 26px;
    height: 26px;
  
}
.bellnumbers {
    font-size:12px;
    margin-top: -21px;
    margin-left: -13px;
    width:16px;
    line-height: 16px;
    text-align: center;
    color:#fff;
    z-index: 2;
    border-radius: 3px;
    position: absolute;
    left: 28px;
}

.w-50{
    width: 50%;
}
.bold-style{
    font-weight: bold;
}
.centered {
    margin-top: -25px;
    color: white;
}
.paragraph-margin {
    margin:5px;
}

.w-100{
    width: 100%;
}

.btn-submit{
    border-radius: 2px;
}

.btn-panel-footer{
    font-size: 0.9rem;
    padding: 0.3em 0.8em;
    border-radius: 2px;
}

.submit-btn-xs{
    position: absolute; 
    left: 35%; 
    bottom: 0px;
}

.slider-btn-wrap{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    .slider-btn{
        background: #000;
        border: 0;
        color: #eee;
        padding: 0.2em 0.4em;
        opacity: 0.5;
        cursor: pointer;
        &:hover{
            opacity: 1;
        }
    }
}


@media (max-width: 1600px) and (min-width: 1280px) {
    .left-pallete{
        flex: 0 0 78%;
        max-width: 78%;
    }
    .right-pallete{
        flex: 0 0 22%;
        max-width: 22%;
    }
}

@media (max-width: 1280px) and (min-width: 992px) {
    .left-pallete{
        flex: 0 0 75%;
        max-width: 75%;
    }
    .right-pallete{
        flex: 0 0 25%;
        max-width: 25%;
    }
}


.overlay-wrapper{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    text-align: center;
    .text-box{
        margin: auto;
    }
}

.overlay-black{
    background: #000;
    opacity: 0.9;
    color: #fff;
}

.overlay-white{
    color: #000;
    opacity: 1;
    background: #fff;
    .btn-close{
        box-shadow: -2pt -2pt 4pt 0pt #90b9e8;
        padding: 0.2em 2em;
    }
}

.btn-text-small{
    font-size: 11px;
    margin-left: 4px;
}


@media (max-width: 576px) {
    .right-pallete{
        z-index: 99;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.ga-modal-wrapper {
    right: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background: rgba(136, 136, 136, 0.6);
    z-index: 5;
    /* z-index: 999; */
}
.ga-modal-container {
    display: flex;
    overflow-y: auto;
    height: 100%;
}
.ga-modal {
    margin: auto;
    background: #fff;
    border: 2pt solid #fff;
    border-radius: .2em;
    /* -webkit-box-shadow: 0 2pt 3pt #999; */
    box-shadow: 0 2pt 3pt #999;
    //min-width: 30%;
    max-width: 50%;
}
.ga-modal-head {
    border-bottom: none;
    font-weight: bold;
    padding: 10px;
}
.ga-modal-body {
    padding: 1em;
    text-align: center;
   /*  padding: 10px 0; */
}
.field-wrapper{
    position: relative;
    padding-top: 10px;
    
    p{
        font-size: 13px;
        z-index: 1;
        float: left;
    }
    .form-ctrl{
    display: block;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 5px;
    outline: none;
    border: solid 1px #e2ebee;
    background: #fff;
    z-index: 10;
    font: 400 12px 'Open sans',sans-serif;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    color: #000;
    } 
    span{
        color: grey;
        font-size: 12px;
    }
}
.selected-file-name{
    padding: 5px;
    border-radius: 4px;
    color: crimson;
    background: aliceblue;
    font-size: 14px
}
.file-close{
    cursor: pointer;
    font-weight: 500;
    color: black !important;
    font-size: 16px !important;
}
.overlay-text{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* height: 100%; */
    color: red;
    font-weight: 100;
    background: antiquewhite;
    white-space: nowrap;
    min-width: 575px;
    z-index: 9;
    margin-left: 10px;
}
